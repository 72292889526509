* {
  font-family: Roboto, sans-serif;
}

body {
  margin: 8px 0;
}

/* 
  For mysterious reasons, Braze in-app messages randomly appear in the app.
  No one has ever been able to track down the root cause -- it is probably something to do with mParticle.
  But either way, this ought to prevent them from actually displaying.
*/
.ab-iam-root {
  display: none !important;
}
